<template>

  <div class="w1314 boardWrap">
    <div class="boardName">
      <h2><span><img src="@/assets/img/icon/icon_faq.svg"></span>{{$t('front.common.faq')}}<em>FAQ</em></h2>
    </div>
    <div class="faqwrap mt20">
      <ul class="tabs">
        <li :class="{'current': faqType == 'all'}" @click="onSelectType('all')">
          <div class="iconWrap"><em class="qnaicon1"></em></div>
          <span>{{$t('front.gameCategory.all')}}</span>
        </li>
        <li :class="{'current': faqType == 'casino'}" @click="onSelectType('casino')">
          <div class="iconWrap"><em class="qnaicon2"></em></div>
          <span>{{$t('front.gameCategory.livecasino')}}</span>
        </li>
        <li :class="{'current': faqType == 'slot'}" @click="onSelectType('slot')">
          <div class="iconWrap"><em class="qnaicon3"></em></div>
          <span>{{$t('front.gameCategory.slot')}}</span>
        </li>
        <!--li>
          <em class="qnaicon4"></em>
          <span>스포츠</span>
        </li>
        <li>
          <em class="qnaicon5"></em>
          <span>E-스포츠</span>
        </li>
        <li>
          <em class="qnaicon6"></em>
          <span>미니게임</span>
        </li-->
        <li :class="{'current': faqType == 'cash'}" @click="onSelectType('cash')">
          <div class="iconWrap"><em class="qnaicon7"></em></div>
          <span>{{$t('front.gameCategory.cash')}}</span>
        </li>
        <li :class="{'current': faqType == 'betInfo'}" @click="onSelectType('betInfo')">
          <div class="iconWrap"><em class="qnaicon8"></em></div>
          <span>{{$t('front.gameCategory.betting')}}</span>
        </li>
        <li :class="{'current': faqType == 'etc'}" @click="onSelectType('etc')">
          <div class="iconWrap"><em class="qnaicon9"></em></div>
          <span>{{$t('front.gameCategory.etc')}}</span>
        </li>
      </ul>
    </div><!-- mybetwrap -->
    <ul class="qnawrap">
      <template v-for="(item, index) in list">
        <li v-if="item && (item.category == faqType || faqType == 'all')" v-bind:key="index + '_' + item.title">
          <h4 class="qnahead" @click="item.isOpen = !item.isOpen">
            <span class="iconWrap"><img src="@/assets/img/icon/icon_faq.svg"></span>
            <!-- <span class="nbicon rdbg" v-if="item.codeName">{{ item.codeName }}</span> -->
            {{ item.title }}
          </h4>
          <div class="qnacon" v-if="!item.isOpen">
            <div class="qnaconin">
              <p v-html="item.content">
              </p>
            </div>
          </div>
        </li>
      </template>

    </ul>

  </div>

</template>

<script>

export default {
  name: 'faqList',
  components: {},
  data () {
    return {
      list: null,
      boardType: 'favor',
      faqType: 'all'
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    onSelectType (value) {
      this.faqType = value
      this.loadList()
    },
    onSearch (value) {
      this.loadList()
    },
    async loadList () {
      const params = {
        boardType: this.boardType
      }

      if (this.faqType !== 'all') {
        params.faqType = this.faqType
      } else {
        if (params.faqType) {
          delete params.faqType
        }
      }

      console.log(params, this.categoryCodeByCode)

      const result = await this.getBoardList(params, this.pageInfo.page)
      this.list = result.list
      this.list.forEach(item => {
        item.content = item.content.replace(/\n/g, '<br>')
        item.isOpen = true
        item.codeName = item.category ? (this.categoryCodeByCode.favor[item.category] ? this.categoryCodeByCode.favor[item.category].codeName : '기타') : '기타'
      })
    },
    onClickRead (id) {
      if (!id) id = 1
      this.$router.push({ name: 'qnaRead', params: { id: id } })
    },
    onClickWrite () {
      this.$router.push({ name: 'qnaWrite' })
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
